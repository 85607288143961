import React from 'react'
import './about.scss'
import aboutpic from '../../assets/pic.png'
import SecHead from '../../components/secHead/SecHead'
import Primbtn from '../../components/Primary btn/Primbtn'



function About() {
    return (
        <div id='scroll-about' data-aos="fade-up" className='About'>
            <div className="container">
                <div className="content">

                    <SecHead subhead="My Introduction" head="About Me" />

                    <div data-aos="zoom-in" className="mid">
                        <div className="img">
                            <img src={aboutpic} alt="" />

                        </div>

                    </div>
                    <div className="bottom">

                        <div className="details">
                            <div className="top">
                                <span className="center" id="desc">
                                Hello! I'm Ekansh Bhushan, a driven and passionate Computer Science student currently enrolled at Delhi Technological University (DTU). My journey in the world of technology and innovation is marked by an insatiable curiosity and a relentless pursuit of excellence.<br/><br/>

My Journey<br/>
My fascination with technology began early, and over the years, I've honed my skills across a diverse array of domains. From mastering Data Structures and Algorithms (DSA) to crafting dynamic and user-centric Full Stack Web Development projects, my goal has always been to push the boundaries of what's possible.
<br/><br/>
Leadership & Innovation<br/>
As the President of SKILLOP (formerly StartupX), I have had the privilege of leading a vibrant community of tech enthusiasts. Our initiatives, ranging from hackathons to mentorship programs, aim to ignite creativity and foster innovation among students. Under my leadership, SKILLOP has become a hub for nurturing technical skills and encouraging collaborative growth.
<br/><br/>
Entrepreneurial Spirit<br/>
I am also the visionary Founder of MOOFLI, a revolutionary social platform dedicated to empowering self-expression through writing. From ideation to execution, I have guided MOOFLI to become a space where voices are heard and stories are shared, fostering meaningful connections and enriching lives.
<br/><br/>
Professional Expertise<br/>
In my role as Project Head at Resumify, I have led a team to revolutionize the job application process. Our innovative tools have significantly increased resume visibility and interview callbacks, making a tangible impact on users' career success. Additionally, as a Web Development Advisor at Connectop, I have provided strategic insights and solutions in responsive web design, helping businesses enhance their online presence.
<br/><br/>
Academic Pursuits<br/>
My academic journey at DTU is complemented by my active participation in various projects and organizations. I am a member of IEEE DTU and serve as an Education Mentor for Desh Ke Mentor, where I guide and support students in their academic endeavors.
<br/><br/>
Skills & Expertise<br/>
Programming Languages: Python, JavaScript, HTML, CSS<br/>
Frameworks & Technologies: Flask, React, Node.js, Express.js, WebRTC<br/>
Domains: Full Stack Web Development, Artificial Intelligence & Machine Learning, Quantum Computing<br/>
Leadership & Management: Project Management, Team Leadership, Strategic Planning<br/><br/>
Let's Connect<br/>
I'm always eager to explore new opportunities and collaborations. Whether it's through professional connections or innovative projects, I believe in the power of technology to create positive change. Let's connect and embark on this exciting journey of innovation and growth together!



                                </span>

                            </div>

                            <div className="count center">

                                <div className="social">
                                    <ul>
                                        <li>
                                            <a
                                                target="_blank" rel="noreferrer"
                                                href="https://github.com/Ekansh-Bhushan"
                                            >
                                                <i className="uil2 fa-brands fa-github"></i>

                                            </a>
                                        </li>
                                         <li>
                                            <a target="_blank" rel="noreferrer"
                                                href="https://www.linkedin.com/in/ekansh-bhushan/">
                                                <i className="uil2 fa-brands fa-linkedin"></i>

                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noreferrer"
                                                href="https://twitter.com/ekanshbhushan">
                                                <i className={`uil2 fa-brands fa-twitter`}></i>

                                            </a>
                                        </li> 
                                        <li>
                                            <a target="_blank" rel="noreferrer"
                                                href="https://www.instagram.com/ekansh__bhushan">
                                                <i
                                                    className='uil2 fa-brands fa-instagram'

                                                    id="uil-instagram-alt" aria-hidden="true"></i>

                                            </a>
                                        </li>
                                    </ul>

                                </div>

                            </div>

                            <a target='_blanck' href="https://drive.google.com/file/d/1flSBadcbnXjjfO3ZBNFSOk2Wsof4IlRP/view?usp=sharing" className="buttons center">
                                <Primbtn value="My Resume" icon={<i className="uil uil-download-alt"></i>} />
                            </a>
                        </div>

                    </div>


                </div>
            </div>

        </div>
    )
}

export default About
